import { Component, Input, HostListener, ElementRef, AfterContentChecked, OnChanges } from '@angular/core'

@Component({
    selector: 'cs-goal-progress-bar-chart',
    templateUrl: './goal-progress-bar-chart.component.html',
    styleUrls: ['./goal-progress-bar-chart.component.scss']
})
export class GoalProgressBarChartComponent implements OnChanges, AfterContentChecked {
    @Input() progressPercentage: number;
    @Input() trackColor: string;
    @Input() fontColor: string;
    @Input() progressedTrackColor: string;
    @Input() goalIcon: string;
    @Input() progressIcon: string;
    @Input() loadOnInit: boolean;
    @Input() orderManagementView: boolean;
    @Input() targets: any;
    @Input() noPercentage: boolean;
    @Input() noZeroStarting: boolean;
    @Input() hideTopPoints: boolean;
    @Input() medalView: boolean;
    @Input() icon: boolean;
    @Input() remainingPoints: number;
    @Input() units: string;

    progressPercentageDelay: number;
    appearedOnce: boolean;
    elementPos: number;
    elementHeight: number;
    scrollPos: number;
    windowHeight: number;
    loaded: boolean = false;

    constructor(public elementRef: ElementRef) { }

    ngOnChanges() {        
        this.loaded = false;
        this.appearedOnce = false;
        this.scroll();
    }

    ngAfterContentChecked() {
        if (this.loadOnInit && !this.loaded) {
            this.scroll();
            this.loaded = true;
        }
    }

    @HostListener('window:scroll')
    onScroll() {
        this.scroll();
    }

    scroll() {
        this.scrollPos = window.scrollY;
        this.windowHeight = window.innerHeight;
        this.elementPos = this.getOffsetTop(this.elementRef.nativeElement);
        this.elementHeight = this.elementRef.nativeElement.clientHeight;
        this.checkVisibility();
    }

    getOffsetTop(element: any) {
        let offsetTop = element.offsetTop || 0;
        if (element.offsetParent) {
            offsetTop += this.getOffsetTop(element.offsetParent);
        }
        return offsetTop;
    }

    checkVisibility() {
        if (!this.appearedOnce) {
            if (this.scrollPos >= this.elementPos || (this.scrollPos + this.windowHeight) >= (this.elementPos)) {
                this.appearedOnce = true;
                this.progressPercentageDelay = this.progressPercentage;
            }
        }
    }
}
<div class="goal-prograss-bar-chart margin-bottom-40 " (scroll)="onScroll()" *ngIf="!noPercentage && !medalView && !orderManagementView">

    <div class="track-container">
        <div class="track" [style.background]="trackColor">
            <div class="bar" [style.width.%]="progressPercentageDelay" [style.background]="progressedTrackColor"></div>
            <div *ngFor="let target of targets" class="progress-dot" [class]="{'tick': target.isAchieved}"
                [style.left]="target.left">
                <span class="level-item">{{target.level}}</span>
            </div>
            <div class="progress-dot goal-dot" [style.left.%]="progressPercentageDelay"></div>
            <div class="progress-icon" [style.left.%]="progressPercentageDelay">
                <em class="icon-trophy-black custom-changes"></em>
                <body-text class="super-heading3 progress-label" [style.color]="fontColor">{{progressPercentageDelay |
                    roundOff : 2}}%
                </body-text>
            </div>
            <div class="text-left">
                <span class="level-item ml-0"  *ngIf="remainingPoints > 0"> {{'GoalProgressBar.msg1' | translate}} {{remainingPoints}} {{units}} {{'GoalProgressBar.msg2' | translate}}</span>
                <span class="level-item ml-0"  *ngIf="remainingPoints == 0">{{'GoalProgressBar.CongratsMessage' | translate}}</span>
            </div>
        </div>

        <div class="goal-icon">
            <em class="icon-flags-icon custom-size"></em>
        </div> 
    </div>
</div>

<div class="goal-prograss-bar-chart margin-bottom-40 " (scroll)="onScroll()" *ngIf="noPercentage"
    [ngClass]="{'hidePercentage': noPercentage}">
    <div class="track-container bg-transparent" [hidden]="hideTopPoints">
        <div class="track" [style.background]="trackColor">
            <div *ngFor="let target of targets" class="progress-dot" [style.left]="target.pointsWidth">
                <span class="level-item-top">{{target.points}} Points</span>
            </div>
        </div>
    </div>
    <div class="track-container">
        <div class="track" [style.background]="trackColor">
            <div class="bar" [style.width.%]="progressPercentageDelay" [style.background]="progressedTrackColor"></div>
            <div class="progress-dot" [style.left]="'0%'" [hidden]="noZeroStarting" placement="top"
            popoverClass="my-custom-class-popover" container="body" triggers="mouseenter:mouseleave"
            [ngbPopover]="'0 Qty'">
                <span class="level-item">0 Qty</span>
            </div>
            <div class="progress-dot" [style.left]="'12%'" *ngIf="targets[0].minQuantity" [hidden]="noZeroStarting" placement="top"
            popoverClass="my-custom-class-popover" container="body" triggers="mouseenter:mouseleave"
            [ngbPopover]="(targets[0].minQuantity ? targets[0].minQuantity + ': ' : '') + 'Each Quantity ' + targets[0].points
            + ' Points'">
                <span class="level-item">{{targets[0].minQuantity ? targets[0].minQuantity : 0}}</span>
            </div>
            <div *ngFor="let target of targets" class="progress-dot" [style.left]="target.qtyWidth"
                [ngClass]="{'bg-transparent-dot': target.bgTransparentDot}" placement="top"
                popoverClass="my-custom-class-popover" container="body" triggers="mouseenter:mouseleave"
                [ngbPopover]="(target.maxQuantity ? target.maxQuantity + ': ' : '') + 'Each Quantity ' + target.points + ' Points'">
                <span class="level-item">{{target.maxQuantity}}</span>
            </div>
            <div class=" goal-dot" [style.left.%]="progressPercentageDelay"></div>
            <div class="progress-icon" [style.left.%]="progressPercentageDelay">
            </div>
        </div>
    </div>

</div>


<div class="goal-prograss-bar-chart margin-bottom-40 medalView" (scroll)="onScroll()" *ngIf="medalView">
    <div class="track-container">
        <div class="track" [style.background]="trackColor">
            <div class="bar" [style.width.%]="progressPercentageDelay" [style.background]="progressedTrackColor"></div>
            <div *ngFor="let target of targets" class="progress-dot" [style.left]="target.qtyWidth">
                <span class="level-item">{{target.maxQuantity}}</span>
            </div>
            <div class=" goal-dot" [style.left.%]="progressPercentageDelay"></div>
            <div class="progress-icon" *ngFor="let target of icon" [style.left]="target.qtyWidth" placement="top"
            popoverClass="my-custom-class-popover" container="body" triggers="mouseenter:mouseleave"
            [ngbPopover]="target.maxQuantity">
                <span>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29.1 40"
                        style="enable-background:new 0 0 29.1 40;" xml:space="preserve">
                        <style type="text/css">
                            .st0 {
                                clip-path: url(#SVGID_2_);
                            }
                            .st1 {
                                fill-rule: evenodd;
                                clip-rule: evenodd;
                                fill: #ABABAB;
                            }
                        </style>
                        <g>
                            <defs>
                                <rect id="SVGID_1_" x="0.3" y="0.2" width="28.4" height="39.5" />
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                            </clipPath>
                            <g id="Group_112" class="st0">
                                <path id="Path_290" class="st1" d="M28,17.3c-0.8-1.4-0.8-3.1,0-4.5l0.3-0.4c0.7-0.9,0.5-2.2-0.3-2.8c-0.2-0.2-0.4-0.3-0.7-0.4
                           L26.8,9c-1.5-0.6-2.5-2-2.7-3.7l0-0.5c0-1.1-0.8-2.1-2-2.1c-0.3,0-0.5,0-0.8,0.1l-0.5,0.1c-1.6,0.4-3.3-0.2-4.3-1.4l-0.3-0.4
                           c-0.6-0.9-1.9-1.2-2.8-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-0.3,0.4c-1.1,1.2-2.7,1.8-4.3,1.4L7.7,2.8C6.6,2.4,5.5,3,5.1,4
                           C5,4.3,5,4.5,5,4.8l0,0.5C4.8,6.9,3.8,8.3,2.3,9L1.8,9.2c-1.1,0.3-1.7,1.4-1.4,2.5c0.1,0.2,0.2,0.5,0.4,0.7l0.3,0.4
                           c0.8,1.4,0.8,3.1,0,4.5l-0.3,0.4c-0.7,0.9-0.5,2.2,0.3,2.8c0.2,0.2,0.4,0.3,0.7,0.4l0.5,0.2c1.5,0.6,2.5,2,2.7,3.7l0,0.5
                           c0,1.1,0.8,2.1,2,2.1c0.3,0,0.5,0,0.8-0.1l0.5-0.1c1.6-0.4,3.3,0.2,4.3,1.4l0.3,0.4c0.6,0.9,1.9,1.2,2.8,0.5
                           c0.2-0.1,0.4-0.3,0.5-0.5l0.3-0.4c1.1-1.2,2.7-1.8,4.3-1.4l0.5,0.1c1.1,0.4,2.2-0.2,2.6-1.2c0.1-0.2,0.1-0.5,0.1-0.8l0-0.5
                           c0.1-1.6,1.2-3,2.7-3.7l0.5-0.2c1.1-0.3,1.7-1.4,1.4-2.5c-0.1-0.3-0.2-0.5-0.4-0.7L28,17.3z M14.5,24.8c-5.4,0-9.7-4.3-9.7-9.7
                           s4.3-9.7,9.7-9.7c5.4,0,9.7,4.3,9.7,9.7C24.2,20.4,19.9,24.7,14.5,24.8"
                           [ngClass]="[target.maxQuantity ? 'svg-' + target.maxQuantity + '-color' : '']"/>
                                <path id="Path_291" class="st1" d="M23,25c-0.5,1-1.5,1.7-2.7,1.7l0,0c-0.3,0-0.7-0.1-1-0.2l-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
                           c-0.6,0-1.2,0.3-1.6,0.8l-0.3,0.4c-0.4,0.6-0.9,1-1.6,1.3v6.3l8,4.5L23,25z"
                           [ngClass]="[target.maxQuantity ? 'svg-' + target.maxQuantity + '-color' : '']"/>
                                <path id="Path_292" class="st1"
                                    d="M11.9,27.6l-0.2-0.4c-0.4-0.5-0.9-0.8-1.5-0.8c-0.1,0-0.2,0-0.4,0.1l-0.4,0.1
                           c-0.3,0.1-0.6,0.2-0.9,0.2c-1.1,0-2.1-0.7-2.5-1.7v14.6l7.4-4.6v-6.2C12.8,28.6,12.3,28.2,11.9,27.6"
                           [ngClass]="[target.maxQuantity ? 'svg-' + target.maxQuantity + '-color' : '']"/>
                                <path id="Path_293" class="st1"
                                    d="M14.5,7.1c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8C22.5,10.6,18.9,7.1,14.5,7.1
                           C14.5,7.1,14.5,7.1,14.5,7.1 M13.9,17.4h-1.7v-5h1.7L13.9,17.4z M16.8,17.4h-1.7v-5h1.7V17.4z"
                           [ngClass]="[target.maxQuantity ? 'svg-' + target.maxQuantity + '-color' : '']"/>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
    </div>

</div>

<div class="goal-prograss-bar-chart margin-bottom-40 " (scroll)="onScroll()" *ngIf="orderManagementView">
    <div class="track-container my-5 order-history-view" *ngIf="orderManagementView">
        <div class="track" [style.background]="trackColor">
            <div class="bar" [style.width.%]="progressPercentageDelay" [style.background]="progressedTrackColor"></div>
            <div *ngFor="let target of targets" class="progress-dot order-history {{target.className}}" [style.left]="target.width"
                [ngClass]="{'bg-transparent-dot': target.bgTransparentDot}">
                <span class="level-item level-item-order-history">{{target.name}}</span>
            </div>
            <div class="goal-dot" [style.left.%]="progressPercentageDelay"></div>
            <div class="progress-icon" [style.left.%]="progressPercentageDelay">
            </div>
        </div>
    </div>

</div>